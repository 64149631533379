'use client';
import { Avatar, Note, Text } from '@ui/components';
import { SidebarLink } from './sidebar-link';
import { ROUTES } from '@constants/routes.constants';
import { useActiveSidebarLink } from './use-active-sidebar-link';
import { twMerge } from 'tailwind-merge';
import { useAppDispatch } from '@lib/redux/store';
import { setScrollPosition } from '@lib/redux/badges/badges';
import { setCreatorId } from '@lib/redux/features/user';

export interface ISidebarCreator {
  avatar: string;
  name: string;
  badgeAmount: number;
  id: string;
  isNotifications: boolean;
}

export function SidebarCreator({ avatar, name, id, badgeAmount, isNotifications }) {
  const href = `${ROUTES.creator}/?id=${id}`;
  const isActive = useActiveSidebarLink(href, id);
  const dispatch = useAppDispatch();

  return (
    <SidebarLink
      href={href}
      isActive={isActive}
      onClick={() => dispatch(setCreatorId(id))}
      className="flex justify-between rounded-3xl p-2">
      <div className="flex gap-2">
        <Avatar
          withGradient={isActive}
          size="sm"
          src={avatar}
          className="border-bgBlack"
          wrapperClassName={isActive && 'to-[#8F00FF] from-[#FFA500]'}
        />
        <div className="flex flex-col overflow-hidden">
          <Text type="l" className="max-w-[160px] truncate text-white">
            {name}
          </Text>
          <Note
            type="s"
            className={twMerge('group-active:text-primary', isActive && 'text-primary')}>
            {badgeAmount} Badge{badgeAmount > 1 && 's'}
          </Note>
        </div>
      </div>

      <div className="flex h-full w-4 items-center self-end">
        {isNotifications ? <div className="bg-primary aspect-square w-4 rounded-full" /> : null}
      </div>
    </SidebarLink>
  );
}

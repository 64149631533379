import React from 'react';
import { store, useAppDispatch, useAppSelector } from '@lib/redux/store';
import { useEventSubscription } from '@cable/hook/use-event-subscription';
import { notificationApi } from '@lib/redux/api/notification-api';
import { challengeApi } from '@lib/redux/api/challenge-api';
import {
  messagesApiV2,
  messagesV2Adapter,
  messagesV2Selector,
  useGetMessagesOfThreadQuery,
  useLazyGetMessagesOfThreadQuery,
} from '@lib/redux/api/messages-api-v2';
import { useGetFanCommunityConversationsQuery } from '@lib/redux/api/conversations-api';
import { IMessagesQueryParams } from '@ui/components';
import { notebookApi } from '@lib/redux/api/cms-api';

const Events: React.FC = () => {
  const dispatch = useAppDispatch();
  const creator_id = useAppSelector((state) => state.user.user.creator_id);
  const user_id = useAppSelector((state) => state.user.user.id);

  const { data } = useGetFanCommunityConversationsQuery(
    {
      fan_id: user_id,
      conversation_type: 'community',
      items_per_page: 10,
      page: 1,
      creator_id: creator_id,
    },
    { skip: !creator_id || !user_id },
  );

  const [getMessagesOfThread] = useLazyGetMessagesOfThreadQuery({
    selectFromResult: ({ data, ...params }) => {
      return {
        data: {
          ...data,
          messages: messagesV2Selector.selectAll(
            data ? data.messages : messagesV2Adapter.getInitialState(),
          ),
        },
        ...params,
      };
    },
  });

  const handlePostNotifiyEvent = async () => {
    try {
      dispatch(notificationApi.util.invalidateTags(['notification']));
      dispatch(
        notificationApi.util.invalidateTags([
          { type: 'notification', id: `post_${user_id}_${creator_id}` },
        ]),
      );

      const threadId = data?.conversations?.entities?.[data?.conversations?.ids[0]]?.thread_id;
      const conversationId = data?.conversations?.ids[0];

      if (threadId && conversationId) {
        // Fetch new data
        const result = await getMessagesOfThread({
          thread_id: threadId,
          creator_id,
          conversation_id: conversationId,
          pageSize: 12,
          page: 1,
          fan_id: user_id,
          ordering: 'desc',
        }).unwrap();

        // After fetching, update cache with sorted data
        const queryParams: IMessagesQueryParams = {
          ordering: 'desc',
          creator_id,
          conversation_id: conversationId,
          thread_id: threadId,
          fan_id: user_id,
          page: 1,
          pageSize: 12,
        };

        dispatch(
          messagesApiV2.util.updateQueryData('getMessagesOfThread', queryParams, (draft) => {
            const sortedMessages = messagesV2Selector
              .selectAll(result.messages)
              .slice()
              .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

            messagesV2Adapter.removeAll(draft.messages);
            messagesV2Adapter.setAll(draft.messages, sortedMessages);
          }),
        );
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleChallengeNotifiyEvent = () => {
    dispatch(
      notificationApi.util.invalidateTags([
        { type: 'notification', id: `challenge_${user_id}_${creator_id}` },
      ]),
    );
    dispatch(notificationApi.util.invalidateTags(['notification']));
    dispatch(challengeApi.util.invalidateTags(['challenges']));
  };

  const handleContentNotifiyEvent = () => {
    dispatch(notificationApi.util.invalidateTags(['notification']));
    dispatch(notebookApi.util.invalidateTags(['exclusiveContents']));
  };

  useEventSubscription('post_notification', handlePostNotifiyEvent);
  useEventSubscription('challenge_notification', handleChallengeNotifiyEvent);
  useEventSubscription('notebook_notification', handleContentNotifiyEvent);
  return null;
};

export default Events;
